import { Drawer, Grid, Icon } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { IClientUpdateManage } from '../../_common/models/clientUpdate/clientUpdateManage.model';
import { PrimaryButton } from '../../_components/buttons/primary.button';
import { SectionHeadingDrawer } from '../../_components/drawer/sectionHeading.drawer';
import { toastr } from '../../_components/toastr/toastr';
import { clientUpdateService } from '../../_services/clientUpdate.service';
import { useDrawerStyles } from '../../_styles/drawer.styles';
import { useEditFormStyles } from '../../_styles/editForm-styles';

export function ClientUpdateManageDrawer(params: { 
    clientUpdateId: number, 
    isOpen: boolean, 
    emitOnClose: () => void }) {

    const [data, setData] = useState<IClientUpdateManage>(getResetData);
    const [isLoading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(params.isOpen);
    const [title, setTitle] = useState('');

    const drawerClasses = useDrawerStyles();
    const editFormClasses = useEditFormStyles();

    useEffect(() => {
        if (params.isOpen !== isOpen) {
            setIsOpen(params.isOpen);
        }

        if(params.clientUpdateId > 0) {
            fetchData();
        }
    }, [params.isOpen]);

    function getResetData(): IClientUpdateManage {
        return {
            id: params.clientUpdateId,
            clientId: 0,
            name: "",
            email: "",
            addressLine1: "",
            addressLine2: "",
            town: "",
            county: "",
            eircode: "",
            contactNumber: "",
            isApproved: true,
            previousName: "",
            previousAddressLine1: "",
            previousAddressLine2:  "",
            previousTown:  "",
            previousCounty:  "",
            previousEircode:  "",
            previousMail:  "",
            previousContactNumber:  "",
        };
    }

    function fetchData() {
        setLoading(true);
        console.log('client update fetch data for client update ', params.clientUpdateId)
        return clientUpdateService
            .get(params.clientUpdateId)
            .then(
                (json: any) => {
                    setTitle(`Update For Client ${json.name}`)
                    setData(json)
                },
                (e) => {
                    toastr.error("Error getting client update data");
                }
            )
            .finally(() => setLoading(false));
    }

    function closeModal() {
        params.emitOnClose();
    }

    function approve(e: any) {
        e.preventDefault();
        setLoading(true);

        return clientUpdateService
            .approve(data)
            .then(
                () => {
                    toastr.success("Approved successfully");
                    params.emitOnClose();
                },
                (e) => {
                    toastr.error("Error saving client update details");
                }
            )
            .finally(() => setLoading(false));
    }

    function onError(event: any) {
        toastr.error("Please verify that all fields have correct input");
    }

    return (
        <Drawer
            anchor={"right"}
            open={isOpen}

            className={drawerClasses.root}
        >
            <ValidatorForm onError={onError} onSubmit={approve} noValidate>

                <SectionHeadingDrawer heading={title} emitOnClose={closeModal} />

                <Grid container>
                <Grid item xs={6} className={[editFormClasses.paddingRight, editFormClasses.boldText].join(' ')}>Previous Value</Grid>
                <Grid item xs={6} className={[editFormClasses.paddingRight, editFormClasses.boldText].join(' ')}>Updated Value</Grid>
                    <Grid item xs={6} className={editFormClasses.paddingRight}>
                        <TextValidator
                            label="Client Name"
                            value={data.previousName}
                            name="name"
                            fullWidth
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextValidator
                            label="Client Name"
                            value={data.name}
                            name="pname"
                            fullWidth
                            onChange={() => { }}
                            variant="filled"
                        />
                    </Grid>

                    <Grid item xs={6} className={editFormClasses.paddingRight}>
                        <TextValidator
                            label="Email"
                            value={data.previousEmail}
                            name="pemail"
                            fullWidth
                            onChange={() => { }}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextValidator
                            label="Email"
                            value={data.email}
                            name="email"
                            fullWidth
                            onChange={() => { }}
                            variant="filled"
                        />
                    </Grid>

                    <Grid item xs={6} className={editFormClasses.paddingRight}>
                        <TextValidator
                            label="Address Line 1"
                            value={data.previousAddressLine1}
                            name="paddressLine1"
                            onChange={() => { }}
                            variant="filled"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextValidator
                            label="Address Line 1"
                            value={data.addressLine1}
                            name="addressLine1"
                            variant="filled"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6} className={editFormClasses.paddingRight}>
                        <TextValidator
                            label="Address Line 2"
                            value={data.previousAddressLine2}
                            name="paddressLine2"
                            onChange={() => { }}
                            variant="filled"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextValidator
                            label="Address Line 2"
                            value={data.addressLine2}
                            name="addressLine2"
                            variant="filled"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={6} className={editFormClasses.paddingRight}>
                        <TextValidator
                            label="Town"
                            value={data.previousTown}
                            name="ptown"
                            onChange={() => { }}
                            variant="filled"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextValidator
                            label="Town"
                            value={data.town}
                            name="town"
                            onChange={() => { }}
                            variant="filled"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={6} className={editFormClasses.paddingRight}>
                        <TextValidator
                            label="County"
                            value={data.previousCounty}
                            name="pcounty"
                            onChange={() => { }}
                            variant="filled"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextValidator
                            label="County"
                            value={data.previousCounty}
                            name="pcounty"
                            onChange={() => { }}
                            variant="filled"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={6} className={editFormClasses.paddingRight}>
                        <TextValidator
                            label="Eircode"
                            value={data.previousEircode}
                            name="peircode"
                            disonChange={() => { }}
                            variant="filled"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextValidator
                            label="Eircode"
                            value={data.eircode}
                            name="eircode"
                            onChange={() => { }}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6} className={editFormClasses.paddingRight}>
                        <TextValidator
                            label="Contact Number"
                            value={data.previousContactNumber}
                            name="pcontactNumber"
                            onChange={() => { }}
                            variant="filled"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextValidator
                            label="Contact Number"
                            value={data.contactNumber}
                            name="contactNumber"
                            onChange={() => { }}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6}>
                            <PrimaryButton
                                type="submit"
                                variant="primary"
                                disabled={isLoading}
                            >
                                <Icon>check</Icon> <>Approve Changes</>
                            </PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <PrimaryButton
                                type="button"
                                variant="danger"
                                disabled={isLoading}
                                onClick={closeModal}
                            >
                                <Icon>remove</Icon> Cancel
                            </PrimaryButton>
                        </Grid>
                </Grid>
            </ValidatorForm>
        </Drawer >
    );
}